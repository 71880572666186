import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { validateFormEmailSending } from '../../../../helpers/validation'

const Result = ({state, updateState, updateSendEmail}) => {
    const handleChange = (name, e) => {
        updateState(name, e.target.value)        
    }
    const handleChangeCheck = () => {
        updateState('terms', !state.terms)          
    }
    const handleSendEmail = (e) => {       
        e.preventDefault()
        const errors = validateFormEmailSending(state.cui, state.branchOffice, state.region)       
        updateState('inProcess', true)
        updateState('errors', errors)    
        if(state.cui && state.branchOffice && state.region && state.terms && state.recaptcha !== ""){
            updateSendEmail(e)
        }else{
            updateState('inProcess', false)
        }      
    }
    const regions = [
        {"name" : "Arica y Parinacota"},
        {"name" : "Tarapacá"},
        {"name" : "Antofagasta"},
        {"name" : "Atacama"},
        {"name" : "Coquimbo"},
        {"name" : "Valparaíso"},
        {"name" : "Metropolitana de Santiago"},
        {"name" : "Libertador General Bernardo O'Higgins"},
        {"name" : "Maule"},
        {"name" : "Ñuble"},
        {"name" : "Biobío"},
        {"name" : "La Araucanía"},
        {"name" : "Los Ríos"},
        {"name" : "Los Lagos"},        
        {"name" : "Aisén del G. Carlos Ibáñez del Campo"},
        {"name" : "Magallanes y de la Antártica Chilena"},
    ]

    return(
        <section>
            <div className="results mb-5">
                <div className="results-title mb-3">
                    <Typography variant="h6" align="center" gutterBottom>
                        {"Estimado(a): " + state.partner.name}
                    </Typography>
                </div>
                <div className="description mb-3">
                    <Typography variant="body2" align="center" gutterBottom>
                        A continuación, se muestra el listado de el (o los) hijos favorecidos en la Premiación Escolar 2019-2020.
                    </Typography>                    
                </div>
                <div className="result-data">
                    <TableContainer component={Paper} className="mb-3">
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Nombre Alumno</TableCell>                                    
                                    <TableCell align="left">Curso</TableCell>
                                    <TableCell align="left">Región Socio</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.students.length > 0 && (
                                    state.students.map((item, index) =>      
                                    <TableRow key={index}>
                                        <TableCell align="left">{item.name}</TableCell>                                        
                                        <TableCell align="left">{item.grade}</TableCell>      
                                        <TableCell align="left">{item.region}</TableCell>                          
                                    </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="body2" align="center" gutterBottom>
                        <strong>*No olvide revisar el nombre de su hijo(a) y el curso. En caso de error, favor notificar a la casilla de premiacionescolar@afubach.cl</strong>
                    </Typography>
                    <Typography variant="body2" align="center" gutterBottom>
                        <strong>Tiene plazo hasta el Viernes 29 de Mayo 2020. De lo contrario, los premios se enviarán con la información proporcionada por usted al momento de la inscripción.</strong>
                    </Typography>
                </div>
            </div>                     
            <hr/>        
            <div className="email-sending">
                <div className="sending-title text-center">
                    <h5>Envío de premios</h5>
                </div>
                <div className="description-sending">
                    <Typography variant="body2" align="center" gutterBottom>
                        Rellenar los campos marcados con *. La información es obligatoria.
                    </Typography>
                </div>
                <div className="sending-form mb-3">
                    <form onSubmit={(e) => handleSendEmail(e)}>
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    required
                                    id="cui"
                                    name="cui"
                                    label="Cui"
                                    fullWidth
                                    value={state.cui}
                                    onChange={(e) => handleChange("cui", e)}
                                />
                                {state.errors.cui !== "" && 
                                    <small className="float-left text-left">{state.errors.cui}</small>
                                }
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    required
                                    id="branchOffice"
                                    name="branchOffice"
                                    label="Sucursal"
                                    fullWidth
                                    value={state.branchOffice}
                                    onChange={(e) => handleChange("branchOffice", e)}
                                />
                                {state.errors.branchOffice !== "" && 
                                    <small className="float-left text-left">{state.errors.branchOffice}</small>
                                }
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Región *</InputLabel>
                                    <Select                                    
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.region}
                                    onChange={(e) => handleChange("region", e)}
                                    fullWidth
                                    >
                                        {regions.map((item, index) => 
                                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                        )}                                        
                                    </Select>
                                    {state.errors.region !== "" && 
                                        <small className="float-left text-left">{state.errors.region}</small>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} className="text-center">
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={state.terms}
                                        onChange={handleChangeCheck}                         
                                        name="checkedB"
                                        color="primary"
                                        required
                                    />
                                    }
                                    label="Acepto el envío. He revisado y validado la información *"
                                />
                                {state.errors.terms !== "" && 
                                    <small className="float-left text-left">{state.errors.terms}</small>
                                }
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12} sm={12} className="text-center">
                                <Button variant="contained" color="primary" type="submit" disabled={state.inProcess}>
                                    Enviar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div className="terms">
                    <Typography variant="body2" align="center" gutterBottom>
                        En caso de consultas o rectificación de Información, contactarse al mail <strong>premiacionescolar@afubach.cl</strong>
                    </Typography>
                </div>
            </div>                                    
        </section>
    )
}

export default Result