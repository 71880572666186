import React, {useState, useEffect}  from 'react'
import Form from './components/form/form'
import Result from './components/result/result'
import Message from './components/message/message'
import axios from 'axios'
import Cookies from 'universal-cookie'
import config from '../../config/config.json'
import { validateFormRut } from '../../helpers/validation'

const Main = () => {
    const [state, setState] = useState({
        errors: {},
        errorToken: "",     
        rut: "",                
        cui: "",
        branchOffice: "",
        region: "",
        recaptcha: "",
        partner: {},
        students: {},
        terms: false,
        inProcess: false,
        formState: "HOME" //HOME - SUCCESS - RUT_NOT_FOUND - MAIL_SENT - DATA_ALREADY_EXISTS - INVALID_EMAIL
    })
    useEffect(() => {
        const cookies = new Cookies()
        const token_exists = cookies.get('_stdf')
        if(!token_exists){
            axios({
                method: 'GET',
                url: config.GEOLOCALIZATION
            }).then(responseA => {
                let ip = Buffer.from(responseA.data.IPv4).toString('base64')
                const params = new URLSearchParams()
                params.append('ip', ip)
                axios({
                    method: 'post',
                    url: config.BACKEND+'main/getAuth',
                    data: params
                }).then(responseB => {
                    cookies.remove('_stdf');
                    cookies.set('_stdf', responseB.data.token , { path: '/', maxAge: '1800' })
                }).catch(errorToken => {
                    setState({
                        errorToken: errorToken
                    })
                });
            })
        }
    }, [])
    const updateState = (name, value) => {
        setState(prevState => ({
          ...prevState,
          [name] : value
        }))
    }
    const updateSubmit = (e) => {
        e.preventDefault()
        var rut = state.rut.replace(/\./g,'-')
        const errors = validateFormRut(rut)
        updateState('errors', errors)
        if(rut && state.recaptcha && !errors.rut){
            const cookies = new Cookies()
            let token = cookies.get('_stdf')
            const params = new URLSearchParams()
            params.append('rut', rut)
            params.append('recaptcha', state.recaptcha)
            axios({
                method: 'POST',
                url: config.BACKEND+'main/searchRut',
                headers: { Authorization: 'Bearer ' + token },
                data: params
            }).then(response => {
                return response       
            }).then(json => {
                if(json.data.success){
                    setState(prevState => ({
                        ...prevState,
                        partner: json.data.data.partner,
                        students: json.data.data.students,
                        errors: {},
                        inProcess: false,
                        formState: "SUCCESS"
                    }))
                }else{
                    setState(prevState => ({ ...prevState, inProcess: false, formState: json.data.error }))
                }                   
            })
            .catch(error => {
                setState(prevState => ({ ...prevState, inProcess: false, formState: "ERROR" }))
            })
        }else{            
            setState(prevState => ({ ...prevState, inProcess: false }))
        }                
    }
    const updateSendEmail = (e) => {
        e.preventDefault()        
        const cookies = new Cookies()
        const params = new URLSearchParams()
        params.append('partnerRut', state.partner.rut)
        params.append('partnerName', state.partner.name)
        params.append('students', JSON.stringify(state.students))
        params.append('cui', state.cui)
        params.append('branchOffice', state.branchOffice)
        params.append('region', state.region)
        axios({
            method: 'POST',
            url: config.BACKEND+'main/sendPartnerEmail',
            headers: { Authorization: 'Bearer ' + cookies.get('_stdf') },
            data: params      
        }).then(json => {
            if(json.data.success){
                setState(prevState => ({
                    ...prevState,
                    errors: {},
                    inProcess: false,
                    formState: "MAIL_SENT"
                }))
            }else{
                setState(prevState => ({ ...prevState, inProcess: false, formState: "ERROR" }))
            }                   
        })
        .catch(error => {
            setState(prevState => ({ ...prevState, inProcess: false, formState: "ERROR" }))
        })
    }
    return(
        <section>
            {state.formState === "HOME" ? 
                <Form state={state} updateState={updateState} updateSubmit={updateSubmit} /> 
                : state.formState === "SUCCESS" ? 
                    <Result state={state} updateState={updateState} updateSendEmail={updateSendEmail} />
                        : <Message message={state.formState} />
            }
        </section>
    )
}

export default Main
