import React from 'react'
import './styles/styles.css'
import logo from './images/logo_afubach.png'

const App = ({ children }) => {
    return (
        <React.Fragment>
          <div className="container">
            <div className="rounded col-lg-6 col-md-12 col-sm-12 col-xs-12 offset-lg-3 px-5">
              <div className="row justify-content-center mb-5">
                <img src={logo} width="136" height="67" alt="logo" /> 
              </div>
              <div className="row justify-content-center">
                {children}
              </div>   
            </div>    
          </div>                  
        </React.Fragment>
    )
}

export default App