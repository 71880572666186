import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'

import App from './App'
import PageNotFound from './scenes/pageNotFound/pageNotFound'
import Main from './scenes/main/main'

render(
    <Router>
        <App>
            <Switch>
                <Route path="/" exact component={Main} />
                <Route component={PageNotFound} />
            </Switch>
        </App>     
    </Router>
   ,
  document.getElementById("app")
)