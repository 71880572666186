import React from 'react'
import Typography from '@material-ui/core/Typography'

const PageNotFound = () => {
    return(
        <section>
            <Typography variant="h6" align="center" gutterBottom>
                Página no encontrada
            </Typography>
        </section>
    )
}

export default PageNotFound
