import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ReCAPTCHA from 'react-google-recaptcha'

const Form = ({ state, updateState, updateSubmit }) => {
    const handleChange = (name, e) => {
        updateState(name, e.target.value)
    }
    const handleSubmit = (e) => {
        updateState('inProcess', true)
        updateSubmit(e)
    }
    const onRecaptchaChange = (value) => {
        updateState('recaptcha', value)
    }

    return(
        <React.Fragment>
            <div className="form-text mb-3">
                <Typography variant="h6" align="center" gutterBottom>
                    Ingresa tu RUT para Validar si tu hija(o) es beneficiario de Premiación Escolar 2019 - 2020
                </Typography>
            </div> 
            <form onSubmit={(e) => handleSubmit(e)}>
                <Grid className="mb-3" container spacing={3} justify="center">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            id="rut"
                            name="rut"
                            label="Rut (formato: 11111111-1)"
                            fullWidth
                            value={state.inputRut}
                            onChange={(e) => handleChange("rut", e)}
                        />
                        {state.errors.rut !== "" && 
                            <small className="float-left text-left">{state.errors.rut}</small>
                        }
                    </Grid>
                </Grid>
                <Grid className="mb-3" container spacing={3} justify="center">
                    <Grid item xs={12} sm={8}>
                        <ReCAPTCHA
                            sitekey="6LdNI_wZAAAAAJEwGWZmaCs5lmcW8_rJqlr9xsrb"
                            onChange={onRecaptchaChange}
                        />           
                    </Grid>
                </Grid>
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={6} className="text-center">
                        <Button variant="contained" color="primary" type="submit" disabled={state.inProcess}>
                            Enviar
                        </Button>
                    </Grid>
                </Grid>                
            </form>
        </React.Fragment>
    )
}

export default Form