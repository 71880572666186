import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const Message = ({message}) => {
    const [state, setState] = useState({
        title: "",
        text: "",
    })
    const handleClick = () => {
        window.location.reload()
    }
    useEffect(() => {
        if(message === "RUT_NOT_FOUND"){
            setState({ 
                title: "No se ha encontrado el rut ingresado",
                text: "Intente nuevamente, en caso de consultas contacte al mail premiacionescolar@afubach.cl"
            })
        }else if(message === "MAIL_SENT"){
            setState({ 
                title: "Datos validados exitosamente",
                text: "Se ha enviado un correo a su dirección de email con la validación del envío de premios."
            })
        }else if(message === "DATA_ALREADY_EXISTS"){
            setState({ 
                title: "Rut ya ha sido validado anteriormente",
                text: "En caso de consultas contacte al mail premiacionescolar@afubach.cl"
            })
        }else if(message === "INVALID_EMAIL"){
            setState({ 
                title: "Email personal inválido",
                text: "El email personal ingresado para postular es incorrecto, por favor comuníquese a premiacionescolar@afubach.cl"
            })
        }else if(message === "ERROR"){
            setState({ 
                title: "Datos inválidos",
                text: "Por favor verifique sus datos e inténtelo nuevamente"
            })
        }             
    }, [message])
    return(
        <section>
            <div className="form-text mb-3">
                <Typography variant="h6" align="center" gutterBottom>
                    {state.title}
                </Typography>
                <Typography className="my-3 px-2" variant="body2" align="center" gutterBottom>
                    {state.text}
                </Typography>
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} sm={12} className="text-center">
                        <Button onClick={handleClick} variant="contained" color="primary" type="button">
                            Volver
                        </Button>
                    </Grid>
                </Grid>                
            </div> 
        </section>
    )
}

export default Message