export const isRutValid = (rut) => {
    if (!rut || rut.trim().length < 3) return false
    const cleanRut = rut.replace(/[^0-9kK-]/g, "")
  
    if (cleanRut.length < 3) return false
  
    const split = cleanRut.split("-")
    if (split.length !== 2) return false
  
    const num = parseInt(split[0], 10)
    const dgv = split[1]
  
    const dvCalc = calculateDV(num)
    return dvCalc === dgv
}
  
export const calculateDV = (rut) => {
    const body = `${rut}`
    let sum = 0
    let multiple = 2
  
    for (let i = 1; i <= body.length; i++) {
      const index = multiple * body.charAt(body.length - i)
  
      sum += index
  
      if (multiple < 7) {
        multiple += 1
      } else {
        multiple = 2
      }
    }
  
    const dvExpected = 11 - (sum % 11)
    if (dvExpected === 10) return "k"
    if (dvExpected === 11) return "0"
    
    return `${dvExpected}`
}

export const validateFormRut = (rut) => {
  let errors = {}
  if(rut === ""){
      errors.rut = "Rut requerido"
  }else if(!isRutValid(rut)){
      errors.rut = "Rut no es válido"      
  }else{
      errors.rut = ""
  }
  return errors
}

export const validateFormEmailSending = (cui, branchOffice, region) => {
  let errors = {}
  if(cui === ""){
      errors.cui = "Cui requerido"
  }
  if(branchOffice === ""){
    errors.branchOffice = "Sucursal requerida"
  }
  if(region === ""){
    errors.region = "Región requerida"
  }
  return errors
}
  